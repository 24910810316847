import { motion } from "framer-motion";
import React from "react";
import { Player } from "../interfaces";
import { itemVariants } from "../motionVariants";
import "./GenInput.css";
import style from "./SharedStyles.module.css";

const GenInput = ({
  style: styleOverride,
  player,
  generation,
  className,
  ...rest
}: {
  style?: React.CSSProperties;
  player?: Player;
  generation?: number;
  className?: string;
} & any) => (
  <motion.div
    key={player?.id || generation}
    className={`GenInput-wrapper
       ${className || ""}
       ${style[`generation-${generation || player?.generation || ""}`]}
       ${style.insetShadow}`}
    variants={itemVariants}
    initial="hidden"
    animate="show"
    style={styleOverride}
    transition={{ type: "spring", duration: 0.3 }}
  >
    <input {...rest} />
  </motion.div>
);

export default GenInput;
