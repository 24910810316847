import { motion } from "framer-motion";
import { useState } from "react";
import useStore from "../../gameStore";
import { Question } from "../../interfaces";
import { containerVariants } from "../../motionVariants";
import GameCard from "../GameCard";
import MainButton from "../MainButton";
import ScoreCard from "../ScoreCard";
import SecondaryButton from "../SecondaryButton";
import "./PlayerScreenPlayGeneration.css";

const PlayerScreenGeneration = () => {
  const { renderedGame: game, updateTurn } = useStore();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [scoreBoard, setScoreBoard] = useState<boolean>(false);
  const [initialQuestion, setInitialQuestion] = useState<Question | null>(null);

  const clickGeneration = (question: Question) => {
    if (!confirm) {
      setConfirm(true);
      setInitialQuestion(question);
    } else {
      updateTurn(game!.turn!.id, {
        generation: question?.generation,
      });
    }
  };
  const handleNevermind = () => {
    setConfirm(false);
    setInitialQuestion(null);
  };

  if (confirm) {
    return (
      <div className="PlayerScreenPlayGeneration confirm">
        {game!.turn!.team.players?.filter(
          (e) => e.generation === initialQuestion!.generation
        ).length === 0 && (
          <div className="PlayerScreenPlayGeneration-bonus">
            <div className="PlayerScreenPlayGeneration-bonus-label">+1</div>
            <div className="PlayerScreenPlayGeneration-bonus-text">
              Outside team's
              <br />
              generation!
            </div>
          </div>
        )}
        <GameCard
          className="PlayerScreenPlayGeneration-card-confirm"
          question={initialQuestion!}
          key={initialQuestion!.id}
          initial="hidden"
          animate="show"
          check={game!.turn?.team?.points_generations.includes(
            initialQuestion!.generation
          )}
        />
        <div>
          <MainButton
            className="PlayerScreenPlayGeneration-confirm"
            onClick={() => clickGeneration(initialQuestion!)}
          >
            Confirm team's choice
          </MainButton>
          <SecondaryButton onClick={handleNevermind}>Back</SecondaryButton>
        </div>
      </div>
    );
  } else if (scoreBoard) {
    return (
      <div className="PlayerScreenPlayGeneration score">
        {game?.teams?.map((team) => (
          <ScoreCard
            key={team.id}
            team={team}
            className="PlayerScreenPlayGeneration-score-card"
          />
        ))}
        <SecondaryButton
          className="PlayerScreenPlayGeneration-score-back"
          onClick={() => setScoreBoard(false)}
        >
          Back
        </SecondaryButton>
      </div>
    );
  } else {
    return (
      <div className="PlayerScreenPlayGeneration">
        <h2 className="PlayerScreenPlayGeneration-title">
          As a team,
          <br /> select a card.
        </h2>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="show"
          className="PlayerScreenPlayGeneration-cards"
        >
          {game?.next_questions
            ?.sort((a, b) => a?.question?.generation - b?.question?.generation)
            .map(({ question }) => (
              <GameCard
                key={question?.id}
                className="PlayerScreenPlayGeneration-card"
                question={question}
                check={game!.turn?.team?.points_generations.includes(
                  question?.generation
                )}
                onClick={() => clickGeneration(question)}
              />
            ))}
        </motion.div>
        <MainButton
          className="PlayerScreenPlayGeneration-show-score"
          onClick={() => setScoreBoard(true)}
        >
          View scoreboard
        </MainButton>
      </div>
    );
  }
};

export default PlayerScreenGeneration;
