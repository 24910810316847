import { motion } from "framer-motion";
import { containerVariants, itemVariants } from "../../motionVariants";
import "./HostStartHeader.css";

const HostStartHeader = ({ small = false }: { small?: boolean }) => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="show"
      className="StartScreen-header"
      style={{ fontSize: small ? ".5rem" : ".8rem" }}
      key="HostHeader"
    >
      <motion.h1
        variants={itemVariants}
        transition={{ type: "spring", duration: 0.1 }}
        key="StartScreen-h1"
      >
        GENSMAK!
      </motion.h1>
      <motion.h2
        variants={itemVariants}
        transition={{ type: "spring", duration: 0.1 }}
        key="StartScreen-h2"
      >
        A party trivia game
        <br />
        for every generation
      </motion.h2>
    </motion.div>
  );
};

export default HostStartHeader;
