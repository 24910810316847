import { now } from "lodash";
import { useEffect, useState } from "react";
import { useLongPress } from "use-long-press";
import useStore from "../../gameStore";
import { Team } from "../../interfaces";
import { useSlideController } from "../../slideController";
import GenTag from "../GenTag";
import Loading from "../Loading";
import MainButton from "../MainButton";
import "./PlayerScreenJoin.css";

const PlayerScreenJoin = () => {
  const longPressThreshold = 2000;
  const longPressUpdate = 10;
  const { renderedGame: game, player, updatePlayer } = useStore();
  const [started, setStarted] = useState<number>(0);
  const [startFill, setStartFill] = useState<number>(0);
  const [gameReady, setGameReady] = useState<boolean>(false);
  const [team, setTeam] = useState<Team>();
  const { startSlides } = useSlideController();

  const callback = startSlides;
  const bind = useLongPress(callback, {
    onStart: () => {
      setStarted(now());
      setStartFill(now());
    },
    onFinish: () => {
      setStarted(0);
      setStartFill(0);
    },
    onCancel: () => {
      setStarted(0);
      setStartFill(0);
    },
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: longPressThreshold,
    captureEvent: true,
    cancelOnMovement: false,
  });

  function switchTeams() {
    updatePlayer(player!.id!, {
      team: game?.teams?.find((t) => t.id !== player?.team)?.id,
    });
  }

  useEffect(() => {
    if (startFill > 0) {
      const interval = setInterval(() => setStartFill(now()), longPressUpdate);
      return () => clearInterval(interval);
    }
  }, [startFill]);

  useEffect(() => {
    setGameReady(
      game?.players !== undefined &&
        game?.players?.length > 1 &&
        game?.teams !== undefined &&
        game?.teams?.every(
          (team) => team?.players?.length && team?.players?.length > 0
        )
    );
  }, [game]);
  useEffect(() => {
    setTeam(game?.teams?.find((t) => t.id === player?.team));
  }, [game, player]);

  if (player === null || player?.id === null) {
    return <Loading />;
  } else {
    return (
      <div className="PlayerScreenJoin">
        <div>
          <h2>{team?.name}</h2>
          <ul className="PlayerScreenJoin-team">
            {team?.players?.map((player) => (
              <GenTag as="li" key={player.id} player={player} small={true} />
            ))}
          </ul>
        </div>
        <div className="PlayerScreenJoin-actions">
          <h3>
            Press and hold start <br />
            once everybody joins
          </h3>
          <MainButton
            disabled={game?.started_at || !gameReady}
            className={"PlayerScreenJoin-start"}
            shouldDebounce={false}
            {...bind()}
          >
            <div
              className="PlayerScreenJoin-start-fill"
              style={{
                width: ((startFill - started) / longPressThreshold) * 100 + "%",
                display: startFill > 0 ? "inline" : "none",
              }}
            ></div>
            <span className="PlayerScreenJoin-start-label">Hold to start</span>
          </MainButton>
          <MainButton onClick={switchTeams}>Switch teams</MainButton>
        </div>
      </div>
    );
  }
};

export default PlayerScreenJoin;
