import delay from "delay";
import omit from "lodash-es/omit";
import pRetry from "p-retry";
import { useEffect } from "react";
import { useMatch, useParams } from "react-router-dom";
import create from "zustand";
import { Game, Owner, Player, QuestionSet } from "./interfaces";
import { getCookie, gtag, setCookie } from "./utils";

export const enum STATES {
  NONE = "NONE",
  ERROR = "ERROR",
  NEW_GAME = "NEW_GAME",
  PLAYER_CODE = "PLAYER_CODE",
  PLAYER_SCREEN_REJOIN = "PLAYER_SCREEN_REJOIN",
  PLAYER_SCREEN_GENERATION = "PLAYER_SCREEN_GENERATION",
  PLAYER_SCREEN_NAME = "PLAYER_SCREEN_NAME",
  PLAYER_SCREEN_JOIN = "PLAYER_SCREEN_JOIN",
  PLAYER_INSTRUCTIONS = "PLAYER_INSTRUCTIONS",
  PLAYER_NEEDS_ID = "PLAYER_NEEDS_ID",
  PLAYER_SCREEN_PLAY_GENERATION = "PLAYER_SCREEN_PLAY_GENERATION",
  PLAYER_SCREEN_PLAY_QUESTION = "PLAYER_SCREEN_PLAY_QUESTION",
  PLAYER_SCREEN_PLAY_CAPTAIN = "PLAYER_SCREEN_PLAY_CAPTAIN",
  PLAYER_SCREEN_PLAY_WAIT = "PLAYER_SCREEN_PLAY_WAIT",
  PLAYER_SCREEN_PLAY_END = "PLAYER_SCREEN_PLAY_END",
  HOST_NEEDS_PASSWORD = "HOST_NEEDS_PASSWORD",
  HOST_START_SCREEN = "HOST_START_SCREEN",
  HOST_INSTRUCTIONS = "HOST_INSTRUCTIONS",
  HOST_SETUP_INSTRUCTIONS = "HOST_SETUP_INSTRUCTIONS",
  HOST_JOIN_SCREEN = "HOST_JOIN_SCREEN",
  HOST_PICK = "HOST_PICK",
  HOST_QUESTION = "HOST_QUESTION",
  HOST_GAME_OVER = "HOST_GAME_OVER",
  HOST_GAME_MENU = "HOST_GAME_MENU",
}

// TODO: this seems broken now, not sure why
const delayedRetry = async (fn: any) => {
  await pRetry(fn, {
    retries: 3,
    onFailedAttempt: async (error) => {
      console.log("Waiting for 3 seconds before retrying", error);
      await delay(3000);
    },
  });
};

export const checkPassword = (password: string | undefined) => {
  if (!process.env.REACT_APP_HOST_PASSWORD) {
    return true;
  }
  var app = getCookie("app");
  if (app === "android" || app === "comcast") {
    return true;
  }
  if (!password) {
    return false;
  }
  return process.env.REACT_APP_HOST_PASSWORD?.split(",").includes(password!);
};

const fetchFromServer = async (
  path: string,
  method: string,
  body: any = null,
  keepalive = false,
  useCsrf = true
) => {
  const res = await fetch(`${process.env.REACT_APP_SERVER_URL}api/${path}/`, {
    method,
    headers: {
      //"access-control-allow-origin": "*",
      "Content-type": "application/json; charset=UTF-8",
      ...(useCsrf ? { "X-CSRFToken": getCookie("csrftoken") ?? "" } : {}),
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
    ...(keepalive ? { keepalive } : {}),
  });
  if (!res.ok) {
    const err: any = new Error(
      "Response " + res.status + ": " + res.statusText
    );
    err.response = res;
    throw err;
  }
  const data = await res.json();
  return data.hasOwnProperty("results") ? data["results"] : data;
};

interface GenSmakState {
  gameState: STATES; // the current game state
  renderedGame?: Game; // the rendered game state (can be delayed)
  liveGame?: Game; // the always up-to-date game state
  nextGame?: string;
  player?: Player;
  owner?: Owner;
  questionSets?: QuestionSet[];
  password?: string;
  muteSounds?: boolean;
  muteMusic?: boolean;
  error?: Error;
  freezeTimes: { [name: string]: number };
  freezeTimeouts: { [name: string]: number };
  showJoinGame: boolean;
  showInstructions: boolean;
  showSetupInstructions: boolean;
  showMenu: boolean;
  previewQuestionSet: number | null;
  slideNumber: number;
  setGameState: (state: STATES) => void; // set the current game state
  setRenderedGame: (renderedGame: Game) => void; // set the rendered game state
  setLiveGame: (liveGame: Game) => void; // set the live game state
  setNextGame: (nextGame: string) => void;
  setPlayer: (player: Player) => void;
  setOwner: (owner: Owner) => void;
  setQuestionSets: (questionSets: QuestionSet[]) => void;
  setPassword: (password: string) => void;
  setMuteSounds: (mute: boolean) => void;
  setMuteMusic: (mute: boolean) => void;
  setError: (error: Error) => void;
  setFreezeTime: (freezeTime: number, key: string) => void;
  setFreezeTimes: (freezeTimeArray: { time: number; key: string }[]) => void;
  setFreezeTimeout: (freezeTimeout: number, key: string) => void;
  setFreezeTimeouts: (freezeTimeoutArray: [number, string][]) => void;
  setShowJoinGame: (showJoinGame: boolean) => void;
  setShowInstructions: (showInstructions: boolean) => void;
  setShowSetupInstructions: (showSetupInstructions: boolean) => void;
  setSlideNumber: (slideNumber: number) => void;
  setShowMenu: (showMenu: boolean) => void;
  setPreviewQuestionSet: (index: number | null) => void;
  toggleMuteSounds: () => void;
  unsetGame: () => void;
  loadGame: (id: string) => void;
  createGame: (game: Game) => void;
  updateGame: (id: string, data: any) => void;
  unsetPlayer: () => void;
  createPlayer: (player: Player) => void;
  updatePlayer: (id: string, data: any) => void;
  loadPlayer: (id: string) => void;
  deletePlayer: (id: string) => void;
  updateTurn: (id: string, data: any) => void;
  loadOwner: (id: string) => void;
  loadQuestionSets: () => void;
}

const useStore = create<GenSmakState>()((set, get) => ({
  gameState: STATES.ERROR,
  muteSounds: false,
  muteMusic: true,
  freezeTimes: { "": 0 },
  freezeTimeouts: { "": 0 },
  showJoinGame: false,
  showInstructions: false,
  showSetupInstructions: false,
  showMenu: false,
  slideNumber: 0,
  slideProgress: 0,
  previewQuestionSet: null,
  setLiveGame: (liveGame) => set({ liveGame }),
  setRenderedGame: (renderedGame) => set({ renderedGame }),
  setNextGame: (nextGame) => set({ nextGame }),
  setPlayer: (player) => set({ player }),
  setOwner: (owner) => set({ owner }),
  setQuestionSets: (questionSets: QuestionSet[]) => set({ questionSets }),
  unsetGame: () =>
    set(
      (state) => ({
        ...omit(state, ["renderedGame", "liveGame", "player", "gameState"]),
        freezeTimeouts: {},
        freezeTimes: {},
      }),
      true
    ),
  setGameState: (gameState) => set({ gameState }),
  setPassword: (password) => set({ password }),
  setMuteSounds: (muteSounds) => set({ muteSounds }),
  toggleMuteSounds: () =>
    set((state) => ({ ...state, muteSounds: !state.muteSounds })),
  setMuteMusic: (muteMusic) => set({ muteMusic }),
  toggleMuteMusic: () =>
    set((state) => ({ ...state, muteMusic: !state.muteMusic })),
  setFreezeTime: (freezeTime, key) => {
    set({ freezeTimes: { ...get().freezeTimes, [key]: freezeTime } });
  },
  setFreezeTimes: (freezeTimeArray) =>
    set({
      freezeTimes: {
        ...get().freezeTimes,
        ...Object.fromEntries(
          freezeTimeArray.map(({ time, key }) => [key, time])
        ),
      },
    }),
  setFreezeTimeout: (freezeTimeout, key) =>
    set({ freezeTimeouts: { ...get().freezeTimeouts, [key]: freezeTimeout } }),
  setFreezeTimeouts: (freezeTimeoutArray) =>
    set({
      freezeTimeouts: {
        ...get().freezeTimeouts,
        ...Object.fromEntries(
          freezeTimeoutArray.map(([timeout, key]) => [key, timeout])
        ),
      },
    }),
  setShowJoinGame: (showJoinGame) =>
    get().showJoinGame !== showJoinGame && set({ showJoinGame }),
  setShowInstructions: (showInstructions) =>
    get().showInstructions !== showInstructions && set({ showInstructions }),
  setShowSetupInstructions: (showSetupInstructions) =>
    get().showSetupInstructions !== showSetupInstructions &&
    set({ showSetupInstructions }),
  setSlideNumber: (slideNumber) =>
    get().slideNumber !== slideNumber && set({ slideNumber }),
  setShowMenu: (showMenu) => set({ showMenu }),
  setPreviewQuestionSet: (index) => {
    if (index !== null) {
      const game = get().liveGame;
      gtag(`${STATES.HOST_START_SCREEN}_PREVIEW_DECK`, game?.id);
    }
    set({ previewQuestionSet: index });
  },
  setError: (error) => set({ error }),
  unsetPlayer: () => set((state) => omit(state, ["player"]), true),
  loadGame: async (id: string) => {
    // NOTE: keeping one example of this commented so we can remember how to do it
    // const run = async () => {
    try {
      const game = await fetchFromServer(`game/${id}`, "GET");
      set({ liveGame: game, error: undefined });
    } catch (err: any) {
      set({ error: err });
      throw err;
    }
    // };
    // await delayedRetry(run);
  },
  createGame: async (data: Game) => {
    try {
      const game = await fetchFromServer("game", "POST", data, false, true);
      set(
        (state) => ({
          ...omit(state, ["player", "gameState", "renderedGame"]),
          liveGame: game,
          error: undefined,
          freezeTimeouts: {},
          freezeTimes: {},
        }),
        true
      );
    } catch (err: any) {
      set({ error: err });
      throw err;
    }
  },
  updateGame: async (id: string, data: any) => {
    try {
      await fetchFromServer(`game/${id}`, "PATCH", data);
      // Don't set game, because it will update from firestore
      set({ error: undefined });
    } catch (err: any) {
      set({ error: err });
      throw err;
    }
  },
  createPlayer: async (data: Player) => {
    try {
      const player = await fetchFromServer("player", "POST", data);
      set({ player, error: undefined });
      setCookie("gameId", player.game);
      setCookie("playerId", player.id);
    } catch (err: any) {
      set({ error: err });
      throw err;
    }
  },
  updatePlayer: async (id: string, data: any) => {
    try {
      const player = await fetchFromServer(`player/${id}`, "PATCH", data);
      set({ player, error: undefined });
    } catch (err: any) {
      set({ error: err });
      throw err;
    }
  },
  loadPlayer: async (id: string) => {
    try {
      const player = await fetchFromServer(`player/${id}`, "GET");
      set({ player, error: undefined });
    } catch (err: any) {
      set({ error: err });
      throw err;
    }
  },
  deletePlayer: async (id: string) => {
    try {
      setCookie("playerId", "");
      await fetchFromServer(`player/${id}`, "DELETE", null, true);
      set((state) => omit(state, ["player"]), true);
    } catch (err: any) {
      set({ error: err });
      throw err;
    }
  },
  updateTurn: async (id: string, data: any) => {
    try {
      await fetchFromServer(`turn/${id}`, "PATCH", data);
      // Don't set game, because it will update from firestore
      set({ error: undefined });
    } catch (err: any) {
      set({ error: err });
      throw err;
    }
  },
  loadOwner: async (id: string) => {
    try {
      const owner = await fetchFromServer(`owner/${id}`, "GET");
      set({ owner, error: undefined });
    } catch (err: any) {
      set({ error: err });
      throw err;
    }
  },
  loadQuestionSets: async () => {
    try {
      const questionSets = await fetchFromServer(`question_set`, "GET");
      set({ questionSets, error: undefined });
    } catch (err: any) {
      set({ error: err });
      throw err;
    }
  },
}));

// Sometimes we want to freeze the state, even if new changes are coming in
// to allow animations to finish. So we use two game objects, one that tracks
// the latest true gamestate, and one that can be temporarily frozen.
// The freezable one is typically what is rendered.
const useDelayedStore = () => {
  const state = useStore();
  useEffect(() => {
    // I assume this "latestState" is bad practive, but I'm getting a race condition
    // where I'm not always getting the latest states in the useEffects.
    // So for now this hack will do.
    const latestState = useStore.getState();
    state.setFreezeTimeouts(
      Object.entries(latestState.freezeTimes)
        .filter(
          ([key, freezeTime]) =>
            freezeTime > 0 && !latestState.freezeTimeouts[key]
        )
        .map(([key, freezeTime]) => [
          window.setTimeout(() => {
            clearTimeout(state.freezeTimeouts[key]);
            state.setFreezeTimeout(0, key);
          }, freezeTime),
          key,
        ])
    );
  }, [state.freezeTimes]);
  useEffect(() => {
    const latestState = useStore.getState();
    Object.entries(latestState.freezeTimeouts).forEach(
      ([key, freezeTimeout]) => {
        if (!freezeTimeout && latestState.freezeTimes[key]) {
          state.setFreezeTime(0, key);
        }
      }
    );
  }, [state.freezeTimeouts]);

  useEffect(() => {
    const latestState = useStore.getState();
    // If the root game is still frozen, no sub-properties can update
    if (latestState.freezeTimeouts[""] || !latestState.liveGame) return;
    // We will get the live game state, and replace its values with the frozen
    // values, if those properties are frozen (have a freeze time > 0)

    //TODO: replace this with a proper deepcopy
    const newGame = JSON.parse(JSON.stringify(latestState.liveGame));
    Object.entries(latestState.freezeTimeouts).forEach(
      ([key, freezeTimeout]) => {
        if (freezeTimeout) {
          if (key === "") return;
          const [prop, subProp] = key.split(".");
          if (subProp) {
            (newGame as any)[prop][subProp] = (state.renderedGame as any)[prop][
              subProp
            ];
          } else {
            (newGame as any)[prop] = (state.renderedGame as any)[prop];
          }
        }
      }
    );
    state.setRenderedGame(newGame);
  }, [state.liveGame, state.freezeTimeouts]);
  return state;
};

const useGameStateStore = () => {
  let { player_id, code } = useParams();
  const state = useDelayedStore();
  const isHost = useMatch("/host/:code");
  const {
    renderedGame: game,
    gameState,
    error,
    player,
    password,
    setGameState,
    setNextGame,
    unsetGame,
    showJoinGame,
    showInstructions,
    showSetupInstructions,
    showMenu,
  } = state;

  const changeGameState = (newGameState: STATES) => {
    if (gameState !== newGameState) {
      gtag(newGameState.toString(), game?.id);
      if (
        newGameState === STATES.HOST_JOIN_SCREEN &&
        getCookie("question_set_name")
      )
        gtag(`game_start_${getCookie("question_set_name")}`, game?.id, true);
      setGameState(newGameState);
    }
  };

  useEffect(() => {
    if (error || game === null || game === undefined) {
      changeGameState(STATES.ERROR);
    } else {
      if (game!.next_game) {
        // Clear any old timeouts
        Object.values(state.freezeTimeouts).forEach((timeout) =>
          clearTimeout(timeout)
        );
        setNextGame(game!.next_game);
        unsetGame();
        changeGameState(STATES.NEW_GAME);
      } else if (isHost) {
        // HOST STATES
        if (
          process.env.REACT_APP_HOST_PASSWORD !== "" &&
          !checkPassword(password)
        )
          changeGameState(STATES.HOST_NEEDS_PASSWORD);
        else if (showMenu) changeGameState(STATES.HOST_GAME_MENU);
        else if (showSetupInstructions) {
          changeGameState(STATES.HOST_SETUP_INSTRUCTIONS);
        } else if (game) {
          if (game!.players?.length === 0 && !showJoinGame) {
            changeGameState(STATES.HOST_START_SCREEN);
          } else if (game!.started_at !== null) {
            if (showInstructions && game.last_turn === null) {
              changeGameState(STATES.HOST_INSTRUCTIONS);
            } else if (game.winner !== null) {
              changeGameState(STATES.HOST_GAME_OVER);
            } else if (game.turn !== null) {
              if (game.turn?.question !== null)
                changeGameState(STATES.HOST_QUESTION);
              else if (game?.next_questions && game?.next_questions?.length > 0)
                changeGameState(STATES.HOST_PICK);
            }
          } else changeGameState(STATES.HOST_JOIN_SCREEN);
        }
      } else {
        // PLAYER STATES
        if (code?.length === 4 && game) changeGameState(STATES.PLAYER_CODE);
        else if (
          !player &&
          game &&
          getCookie("gameId") == game.id &&
          getCookie("playerId")
        )
          changeGameState(STATES.PLAYER_SCREEN_REJOIN);
        else if (!player || !player?.generation)
          changeGameState(STATES.PLAYER_SCREEN_GENERATION);
        else if (!player?.name) changeGameState(STATES.PLAYER_SCREEN_NAME);
        else if (!game?.started_at || !player?.id)
          changeGameState(STATES.PLAYER_SCREEN_JOIN);
        else if (showInstructions && game.last_turn === null)
          changeGameState(STATES.PLAYER_INSTRUCTIONS);
        else if (game?.started_at && player.id && player_id === undefined)
          changeGameState(STATES.PLAYER_NEEDS_ID);
        else if (game?.started_at && player_id !== undefined) {
          if (game!.turn === null || game!.turn === undefined)
            changeGameState(STATES.ERROR);
          else {
            if (game!.winner !== null)
              changeGameState(STATES.PLAYER_SCREEN_PLAY_END);
            else if (game!.turn!.team?.id === player!.team) {
              if (
                game!.turn!.generation === null &&
                game?.next_questions &&
                game?.next_questions?.length > 0
              )
                changeGameState(STATES.PLAYER_SCREEN_PLAY_GENERATION);
              else if (game!.turn!.question)
                changeGameState(STATES.PLAYER_SCREEN_PLAY_QUESTION);
            } else {
              if (game!.turn!.question)
                changeGameState(STATES.PLAYER_SCREEN_PLAY_CAPTAIN);
              else changeGameState(STATES.PLAYER_SCREEN_PLAY_WAIT);
            }
          }
        }
      }
    }
  }, [
    game,
    player,
    error,
    password,
    player_id,
    isHost,
    code,
    showJoinGame,
    showInstructions,
    showSetupInstructions,
    showMenu,
  ]);

  return state;
};

export default useGameStateStore;
