import Matter from "matter-js";
import { useEffect, useRef } from "react";
import smileImg from "../images/smile.png";
import "./PhysicsStickers.css";
import "./SharedStyles.module.css";

const PhysicsStickers = ({
  className,
  superbounce = false,
  ballRadius = 40,
  numBalls = 7,
}: {
  className?: string;
  superbounce?: boolean;
  ballRadius?: number;
  numBalls?: number;
}) => {
  const worldRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      if (worldRef.current) {
        const Engine = Matter.Engine,
          Render = Matter.Render,
          Runner = Matter.Runner,
          Bodies = Matter.Bodies,
          MouseConstraint = Matter.MouseConstraint,
          Mouse = Matter.Mouse,
          Composite = Matter.Composite;
        const engine = Engine.create();
        const render = Render.create({
          element: worldRef.current,
          engine: engine,
          options: {
            background: "transparent",
            wireframeBackground: "transparent",
            width: worldRef.current.clientWidth,
            height: worldRef.current.clientHeight,
            wireframes: false,
          },
        });
        const IMG_DIM = 94;
        const balls = Array(superbounce ? numBalls * 2 : numBalls)
          .fill(0)
          .map((_, i) =>
            Bodies.circle(
              Math.random() * worldRef.current!.clientWidth,
              superbounce
                ? (Math.random() * worldRef.current!.clientHeight) / 2
                : Math.random() * worldRef.current!.clientHeight,
              ballRadius,
              {
                density: superbounce ? 0.000001 : 0.0004,
                frictionAir: superbounce ? 0 : 0.001,
                restitution: superbounce ? 1 : 0.97,
                friction: superbounce ? 0 : 0.001,
                render: {
                  sprite: {
                    texture: smileImg,
                    xScale: (ballRadius / IMG_DIM) * 2,
                    yScale: (ballRadius / IMG_DIM) * 2,
                  },
                },
              }
            )
          );
        let walls = [
          Bodies.rectangle(
            worldRef.current.clientWidth / 2,
            0,
            worldRef.current.clientWidth,
            20,
            { isStatic: true, render: { visible: false } }
          ),
          Bodies.rectangle(
            worldRef.current.clientWidth / 2,
            worldRef.current.clientHeight,
            worldRef.current.clientWidth,
            20,
            { isStatic: true, render: { visible: false } }
          ),
          Bodies.rectangle(
            0,
            worldRef.current.clientHeight / 2,
            20,
            worldRef.current.clientHeight,
            { isStatic: true, render: { visible: false } }
          ),
          Bodies.rectangle(
            worldRef.current.clientWidth,
            worldRef.current.clientHeight / 2,
            20,
            worldRef.current.clientHeight,
            { isStatic: true, render: { visible: false } }
          ),
        ];
        Composite.add(engine.world, [...balls, ...walls]);
        Render.run(render);
        const runner = Runner.create();
        Runner.run(runner, engine);
        // add mouse control
        var mouse = Mouse.create(render.canvas),
          mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
              stiffness: 0.2,
              render: {
                visible: false,
              },
            },
          });

        Composite.add(engine.world, mouseConstraint);

        // keep the mouse in sync with rendering
        render.mouse = mouse;
      }
    }, 200); // bad hack to let dom elements render/resize
  }, []);
  return <div className={`PhysicsStickers ${className}`} ref={worldRef} />;
};

export default PhysicsStickers;
