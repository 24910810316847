import { motion } from "framer-motion";
import { ReactElement, useEffect, useState } from "react";
import MainButton from "./MainButton";
import SecondaryButton from "./SecondaryButton";
import "./SlideUpModal.css";

const SlideUpModal = ({
  warning,
  buttonCopy,
  onClick,
  onChange,
}: {
  warning: ReactElement;
  buttonCopy: string;
  onClick: () => any;
  onChange?: (value: boolean) => any;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [fullyOpen, setFullyOpen] = useState<boolean>(false);

  useEffect(() => {
    if (onChange) {
      onChange(open);
    }
  }, [open]);

  const wrappedOnClick = () => {
    if (!open) {
      setOpen(true);
      setFullyOpen(true);
    } else {
      setOpen(false);
      setTimeout(() => setFullyOpen(false), 300);
      onClick();
    }
  };

  const clickCancel = () => {
    setOpen(false);
    setTimeout(() => setFullyOpen(false), 300);
  };

  return (
    <>
      <motion.div
        style={{
          opacity: open ? 1 : 0,
        }}
        className="SlideUpModal-background"
      />
      <div
        className={`SlideUpModal ${open ? "SlideUpModal-open" : ""} ${
          fullyOpen ? "SlideUpModal-fully-open" : ""
        }`}
      >
        <p
          style={{
            opacity: open ? 1 : 0,
            maxHeight: open ? 500 : 0,
            translate: open ? "0 20" : 0,
            marginBottom: open ? 48 : 0,
          }}
          className="SlideUpModal-warning"
        >
          {warning}
        </p>
        <MainButton
          className="SlideUpModal-button"
          onClick={wrappedOnClick}
          style={{ marginBottom: open ? 0 : "24px" }}
        >
          {buttonCopy}
        </MainButton>
        <SecondaryButton
          disabled={!open}
          className="SlideUpModal-cancel"
          style={{
            opacity: open ? 1 : 0,
            maxHeight: open ? 100 : 0,
            padding: open ? 16 : 0,
            marginTop: open ? 16 : 0,
          }}
          onClick={clickCancel}
        >
          Cancel
        </SecondaryButton>
      </div>
    </>
  );
};

export default SlideUpModal;
