import { useCallback, useEffect, useRef, useState } from "react";
import useStore from "./gameStore";

export const slideDuration = 12000;
export const numSlides = 4;
const totalDuration = slideDuration * numSlides;

const useStartedAt = () => {
  const { renderedGame: game } = useStore();
  const startedAt = useRef(new Date(0));
  useEffect(() => {
    if (game?.started_at) {
      startedAt.current = new Date(game?.started_at);
    }
  }, [game?.started_at]);
  return startedAt;
};
// This hook should only be used once on the top level of each host and player
// so as not to create multiple timers
export const useSlideTimer = () => {
  const {
    renderedGame: game,
    setShowInstructions,
    setSlideNumber,
  } = useStore();
  const startedAt = useStartedAt();
  const requestRef = useRef(0);
  // NOTE: There is probably a more proper way to do this, but this works for now
  const animate = useCallback(() => {
    const now = new Date();
    if (now < startedAt.current) {
      const leftInShow = startedAt.current.getTime() - now.getTime();
      const elapsedInShow = totalDuration - leftInShow;
      setShowInstructions(true);
      setSlideNumber(Math.max(Math.floor(elapsedInShow / slideDuration), 0));
      requestRef.current = requestAnimationFrame(animate);
    } else {
      setShowInstructions(false);
    }
  }, []);
  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [game?.started_at]);
};

export const useSlideProgress = () => {
  const { renderedGame: game } = useStore();
  const startedAt = useStartedAt();
  const requestRef = useRef(0);
  const [slideProgress, setSlideProgress] = useState(0);
  const animate = useCallback(() => {
    const now = new Date();
    if (now < startedAt.current) {
      const leftInShow = startedAt.current.getTime() - now.getTime();
      const elapsedInShow = totalDuration - leftInShow;
      setSlideProgress((elapsedInShow % slideDuration) / slideDuration);
      requestRef.current = requestAnimationFrame(animate);
    }
  }, []);
  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [game?.started_at]);
  return { slideProgress };
};

export const useSlideController = () => {
  const { renderedGame: game, updateGame, slideNumber } = useStore();

  const skipSlide = useCallback(() => {
    if (slideNumber < numSlides - 1) {
      const durationOfRemainingSlides =
        (numSlides - slideNumber - 1) * slideDuration;
      const endOfShow = new Date();
      endOfShow.setSeconds(
        endOfShow.getSeconds() + durationOfRemainingSlides / 1000
      );
      updateGame(game!.id!, {
        started_at: endOfShow.toISOString(),
      });
    }
  }, [game?.id, slideNumber]);

  const endSlides = useCallback(() => {
    updateGame(game!.id!, {
      started_at: new Date().toISOString(),
    });
  }, [game?.id]);

  const startSlides = useCallback(() => {
    const now = new Date();
    now.setSeconds(now.getSeconds() + totalDuration / 1000);
    updateGame(game!.id!, {
      started_at: now.toISOString(),
    });
  }, [game?.id]);

  return {
    skipSlide,
    endSlides,
    startSlides,
  };
};
