import Marquee from "react-fast-marquee";
import "./MarqueeRainbow.css";

import sharedStyles from "./SharedStyles.module.css";
const generations = [
  { className: "generation-1922", speed: Math.random() * 20 + 20 },
  { className: "generation-1946", speed: Math.random() * 20 + 20 },
  { className: "generation-1965", speed: Math.random() * 20 + 20 },
  { className: "generation-1981", speed: Math.random() * 20 + 20 },
  { className: "generation-1997", speed: Math.random() * 20 + 20 },
];

const MarqueeRainbow = ({
  children,
  ...rest
}: { children: React.ReactNode | React.ReactNode[] | string } & any) => {
  return (
    <div className="MarqueeRainbow">
      {generations.map(({ className, speed }) => (
        <Marquee
          {...rest}
          speed={speed}
          key={`marquee-${className}`}
          className={`MarqueeRainbow-marquee ${sharedStyles[className]}`}
          gradient={false}
        >
          {Array(10).fill(`${children}\xa0\xa0\xa0\xa0`).join("")}
        </Marquee>
      ))}
    </div>
  );
};

export default MarqueeRainbow;
