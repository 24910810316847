import { doc, DocumentSnapshot, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useFirestore } from "reactfire";
import { useArrowKeyNav } from "../arrowKeyNav";
import useStore, { STATES } from "../gameStore";
import { useSlideTimer } from "../slideController";
import { getCookie, setCookie } from "../utils";
import GameMarquee from "./GameMarquee";
import GameMenu from "./GameMenu";
import GameMenuButton from "./GameMenuButton";
import HostGameOver from "./host/HostGameOver";
import HostInstructions from "./host/HostInstructions";
import HostJoinScreen from "./host/HostJoinScreen";
import HostPassword from "./host/HostPassword";
import HostPick from "./host/HostPick";
import HostQuestion from "./host/HostQuestion";
import HostSetupInstructions from "./host/HostSetupInstructions";
import HostStartScreen from "./host/HostStartScreen";
import "./HostScreen.css";
import Loading from "./Loading";
const HostScreen = () => {
  const { code } = useParams();
  const {
    renderedGame: game,
    setLiveGame,
    nextGame,
    password,
    setPassword,
    setFreezeTimes,
    gameState,
    questionSets,
    loadQuestionSets,
    setMuteSounds,
  } = useStore();
  useSlideTimer();
  useEffect(() => {
    document.documentElement.setAttribute("data-device", "host");
    if (!questionSets) {
      loadQuestionSets();
    }
  }, []);
  useArrowKeyNav(getCookie("app") === "comcast");
  const gameRef = doc(useFirestore(), "games", code!);
  useEffect(() => {
    // TODO: load owner record and update qusetion set ownership
  }, [questionSets]);
  useEffect(() => {
    const app = getCookie("app");
    if (app === "android") {
      // Bypass for android
      setCookie("password", process.env.REACT_APP_HOST_PASSWORD || "");
      setMuteSounds(true);
    }
    const passwordCookie = getCookie("password");
    if (!password && passwordCookie) {
      setPassword(passwordCookie);
    }
    const unsubscribe = onSnapshot(gameRef!, (doc: DocumentSnapshot<any>) => {
      const newData = doc.data();
      if (newData && (!game || game.updated_at !== newData.updated_at)) {
        if (
          gameState === STATES.HOST_QUESTION &&
          newData.turn.id !== game!.turn!.id
        ) {
          const correct = newData?.last_turn?.correct;
          setFreezeTimes([
            { time: 5000, key: "turn.question" },
            { time: correct ? 8500 : 5000, key: "teams" },
            { time: correct ? 9250 : 5000, key: "turn.team" },
          ]);
        }
        setLiveGame(newData);
      }
    });
    return () => unsubscribe();
  }, [gameState, game?.updated_at, game?.turn?.id]);
  switch (gameState) {
    case STATES.ERROR:
      return <Loading />;
    case STATES.HOST_NEEDS_PASSWORD:
      return <HostPassword />;
    case STATES.NEW_GAME:
      return nextGame ? <Navigate to={`/host/${nextGame}`} /> : null;
    default:
      return (
        <div id="HostScreen" className="screen">
          {gameState !== STATES.HOST_GAME_MENU && <GameMarquee />}
          <main>
            {gameState === STATES.HOST_START_SCREEN && <HostStartScreen />}
            {gameState === STATES.HOST_JOIN_SCREEN && <HostJoinScreen />}
            {gameState === STATES.HOST_PICK && <HostPick />}
            {gameState === STATES.HOST_INSTRUCTIONS && <HostInstructions />}
            {gameState === STATES.HOST_SETUP_INSTRUCTIONS && (
              <HostSetupInstructions />
            )}
            {gameState === STATES.HOST_QUESTION && <HostQuestion />}
            {gameState === STATES.HOST_GAME_OVER && <HostGameOver />}
            {gameState !== STATES.HOST_GAME_MENU && <GameMenuButton />}
            {gameState === STATES.HOST_GAME_MENU && <GameMenu />}
          </main>
        </div>
      );
  }
};

export default HostScreen;
