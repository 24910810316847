import { useState } from "react";
import useStore from "../../gameStore";
import GenTag from "../GenTag";
import MainButton from "../MainButton";
import "./PlayerScreenGeneration.css";

const generations = [
  { year: 1922, years: "1922-1945", name: "Greatest" },
  { year: 1946, years: "1946-1964", name: "Boomer" },
  { year: 1965, years: "1965-1980", name: "Gen X" },
  { year: 1981, years: "1981-1996", name: "Millennial" },
  { year: 1997, years: "1997-2012", name: "Gen Z" },
];

const PlayerScreenGeneration = () => {
  const { renderedGame: game, setPlayer } = useStore();
  const [generation, setGeneration] = useState<number>(0);

  const onChange = (val: number) => {
    setGeneration(val);
  };

  const setPlayerGeneration = () => {
    setPlayer({ game: game!.id, generation: generation });
  };

  return (
    <div className="PlayerScreenGeneration">
      <h2 className="PlayerScreenGeneration-title">When were you born?</h2>
      <div className="PlayerScreenGeneration-generations">
        {generations.map(({ year, years, name }) => (
          <GenTag
            as="button"
            key={year}
            className="PlayerScreenGeneration-generation"
            onClick={() => onChange(year)}
            generation={generation === year ? year : ""}
          >
            {generation === year ? name : years}
          </GenTag>
        ))}
      </div>
      <MainButton
        className="PlayerScreenGeneration-next"
        onClick={setPlayerGeneration}
        disabled={generation === 0}
      >
        NEXT
      </MainButton>
    </div>
  );
};

export default PlayerScreenGeneration;
