import { motion } from "framer-motion";
import React, { useCallback, useEffect, useRef } from "react";
import style from "./SecondaryButton.module.css";

import { debounce } from "lodash";
const SecondaryButton = ({
  onClick = () => {},
  children,
  className,
  style: styleOverride,
  shouldDebounce = true,
  ...rest
}: {
  onClick: () => any;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  shouldDebounce?: boolean;
} & any) => {
  const _onClick = useRef(onClick);
  useEffect(() => {
    _onClick.current = onClick;
  }, [onClick]);
  const debouncedClick = useCallback(
    shouldDebounce
      ? debounce(() => _onClick.current(), 500, {
          leading: true,
          trailing: false,
        })
      : () => _onClick.current(),
    []
  );
  return (
    <motion.button
      {...rest}
      className={`${className || ""} ${style.button}`}
      onClick={debouncedClick}
      style={styleOverride}
    >
      {children}
    </motion.button>
  );
};

export default SecondaryButton;
