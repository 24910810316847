import { useEffect } from "react";
import useStore from "../../gameStore";
import { soundEffects } from "../../sound";
import GenTag from "../GenTag";
import MarqueeRainbow from "../MarqueeRainbow";
import PhysicsStickers from "../PhysicsStickers";
import "./HostGameOver.css";

const HostGameOver = (props: any) => {
  const { renderedGame: game, muteSounds } = useStore();

  useEffect(() => {
    if (!muteSounds) {
      soundEffects.applause.play();
      soundEffects.horn.play();
    }
  }, [muteSounds]);
  return (
    <div className="HostGameOver">
      <h1>
        {`${game!.teams!.find((o) => o.id === game!.winner)?.name} WINS!`}
        <br />
        <ul className="HostGameOver-team">
          {game!
            .teams!.find((o) => o.id === game!.winner)!
            .players?.map((player) => (
              <GenTag as="li" key={player.id} player={player} small={true} />
            ))}
        </ul>
      </h1>
      <MarqueeRainbow>
        {`${game!.teams!.find((o) => o.id === game!.winner)!.name} WINS!`}
      </MarqueeRainbow>
      <PhysicsStickers superbounce />
    </div>
  );
};
export default HostGameOver;
