import { motion } from "framer-motion";
import React, { ElementType } from "react";
import { Player } from "../interfaces";
import { itemVariants } from "../motionVariants";
import "./GenTag.css";
import style from "./SharedStyles.module.css";

const GenTag = ({
  style: styleOverride,
  player,
  generation,
  small = false,
  as: Tag,
  children,
  className,
  ...rest
}: {
  style?: React.CSSProperties;
  player?: Player;
  generation?: number;
  small?: boolean;
  as?: ElementType;
  children?: React.ReactNode | React.ReactNode[] | string;
  className?: string;
} & any) =>
  Tag ? (
    <Tag
      key={player?.id || generation || children}
      className={`GenTag-name
         ${className || ""}
         ${style[`generation-${generation || player?.generation || ""}`]}
         ${small ? "GenTag-small" : style.insetShadow}`}
      style={styleOverride}
      {...rest}
    >
      {Tag !== "input" ? children || player?.name || "" : null}
    </Tag>
  ) : (
    <motion.span
      variants={itemVariants}
      initial="hidden"
      animate="show"
      key={player?.id || generation}
      transition={{ type: "spring", duration: 0.5 }}
      className={`GenTag-name
            ${className || ""}
            ${style[`generation-${generation || player?.generation || ""}`]}
            ${small ? "GenTag-small" : style.insetShadow}`}
      style={styleOverride}
      {...rest}
    >
      {children || player?.name || ""}
    </motion.span>
  );

export default GenTag;
