import useStore from "../gameStore";
import smileImg from "../images/smile.svg";
import "./Loading.css";
import style from "./SharedStyles.module.css";

const Loading = () => {
  const { error } = useStore();
  return (
    <div id="LoadingScreen">
      <p>
        {error
          ? `Sorry! We had an error (${error.message}). \nTry refreshing the browser.`
          : "Loading..."}
      </p>
      <img src={smileImg} alt="smile" className="LoadingScreen-smile" />
      {error && (
        <button
          onClick={() => window.location.reload()}
          className={style.roundShadow}
        >
          Refresh
        </button>
      )}
    </div>
  );
};

export default Loading;
