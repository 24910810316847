import useStore from "../gameStore";
import menu from "../images/menu.svg";
import "./GameMenuButton.css";

const GameMenuButton = () => {
  const { setShowMenu } = useStore();

  return (
    <button className="GameMenuButton" onClick={() => setShowMenu(true)}>
      <img src={menu} alt="show-menu" />
    </button>
  );
};
export default GameMenuButton;
