import { motion } from "framer-motion";
import { textVariants } from "../motionVariants";
import style from "./QuestionSection.module.css";

const QuestionSection = ({ title, body }: { title: string; body?: string }) => (
  <>
    <motion.h3
      className={style.header}
      variants={textVariants}
      initial="hidden"
      animate="show"
    >
      {title}
    </motion.h3>
    {body && (
      <motion.p
        className={style.par}
        variants={textVariants}
        initial="hidden"
        animate="show"
      >
        {body}
      </motion.p>
    )}
  </>
);

export default QuestionSection;
