import React, { useState } from "react";
import useStore from "../../gameStore";
import GenInput from "../GenInput";
import MainButton from "../MainButton";
import "./PlayerScreenName.css";
const maxNameLength = 10;
const PlayerScreenName = () => {
  const { player, createPlayer } = useStore();
  const [name, setName] = useState<string>("");
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inProgressName = event.target.value;
    inProgressName = inProgressName.trimStart();
    inProgressName = inProgressName.slice(0, maxNameLength);
    setName(inProgressName);
  };

  const setPlayerName = () => {
    player!.name = name.trim();
    createPlayer(player!);
  };

  return (
    <div className="PlayerScreenName shifted">
      <h2 className="PlayerScreenName-title">What's your name?</h2>
      <GenInput
        autoFocus
        type="text"
        generation={player?.generation}
        maxLength={maxNameLength}
        onChange={onChange}
        placeholder="name"
        className="PlayerScreenName-input"
        value={name}
      />
      <MainButton
        disabled={!name.trim()}
        className="PlayerScreenName-next"
        onClick={setPlayerName}
      >
        NEXT
      </MainButton>
    </div>
  );
};

export default PlayerScreenName;
