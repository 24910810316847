import { motion } from "framer-motion";
import cardFfBooksMusic from "../images/FF_Books_Music.svg";
import cardFfChefsRestaurants from "../images/FF_Chefs_Restaurants.svg";
import cardFfFoodBrands from "../images/FF_Food_Brands.svg";
import cardFfFoodTrends from "../images/FF_Food_Trends.svg";
import cardFfFoodOnTheInternet from "../images/FF_Food_on_the_internet.svg";
import cardFfTvMovies from "../images/FF_TV_Movies.svg";
import cardBoomer from "../images/card_boomer.svg";
import cardEasy from "../images/card_easy.svg";
import cardGenNeeded from "../images/card_gen_needed.svg";
import cardGenUnlocked from "../images/card_gen_unlocked.svg";
import cardGenX from "../images/card_gen_x.svg";
import cardGenZ from "../images/card_gen_z.svg";
import cardGreatest from "../images/card_greatest.svg";
import cardHard from "../images/card_hard.svg";
import cardMedium from "../images/card_medium.svg";
import cardMillennial from "../images/card_millennial.svg";
import cardMusic from "../images/card_music.svg";
import cardPeopleAndEvents from "../images/card_people_and_events.svg";
import cardPopCulture from "../images/card_pop_culture.svg";
import cardShadow from "../images/card_shadow.svg";
import cardShowsAndFilms from "../images/card_shows_and_films.svg";
import cardWomenInSports from "../images/card_women_in_sports.svg";
import { Question } from "../interfaces";
import { itemVariants } from "../motionVariants";
import "./GameCard.css";

const GameCard = ({
  question,
  check,
  onClick,
  className,
  style: styleOverride,
  initial,
  animate,
}: {
  question: Question;
  check?: Boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string;
  initial?: string;
  animate?: string;
}) => {
  const { category, difficulty, generation } = question;
  const generationToImage: { [key: number]: string } = {
    1922: cardGreatest,
    1946: cardBoomer,
    1965: cardGenX,
    1981: cardMillennial,
    1997: cardGenZ,
  };
  const difficultyToImage: { [key: number]: string } = {
    1: cardEasy,
    2: cardMedium,
    3: cardHard,
  };

  const categoryToImage: { [key: string]: string } = {
    "Music": cardMusic,
    "Shows \n& Films": cardShowsAndFilms,
    "People \n& Events": cardPeopleAndEvents,
    "Pop \nCulture": cardPopCulture,
    "Books \n& Music": cardFfBooksMusic,
    "Chefs \n& Restaurants": cardFfChefsRestaurants,
    "Food Brands": cardFfFoodBrands,
    "Food on the Internet": cardFfFoodOnTheInternet,
    "Food Trends": cardFfFoodTrends,
    "TV \n& Movies": cardFfTvMovies,
    "Women in Sports": cardWomenInSports,
  };
  return (
    <motion.div
      variants={itemVariants}
      transition={{ type: "spring" }}
      initial={initial}
      animate={animate}
      className={"card " + className || ""}
      onClick={onClick}
      key={question?.id}
      style={styleOverride}
    >
      <img src={cardShadow} className="card-shadow" alt="card shadow" />
      <img
        className="card-background"
        src={generationToImage[generation]}
        alt={`Card generation: ${generation}`}
      />
      <img
        className="card-difficulty"
        src={difficultyToImage[difficulty]}
        alt={`Card difficulty: ${difficulty}`}
      />

      {category && categoryToImage[category] && (
        <img
          className="card-category"
          src={categoryToImage[category]}
          alt={`Card category: ${category}`}
        />
      )}
      {category && categoryToImage[category] == undefined && (
        <span className="card-category-text">{category}</span>
      )}
      <img
        className="card-check"
        src={check ? cardGenUnlocked : cardGenNeeded}
        alt={`Card check: ${check}`}
      />
    </motion.div>
  );
};

export default GameCard;
