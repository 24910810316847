import { motion } from "framer-motion";
import useStore from "../gameStore";
import "./ResultSticker.css";
import "./SharedStyles.module.css";

const ResultSticker = () => {
  const { renderedGame: game } = useStore();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
      className="ResultSticker-background"
    >
      <motion.div
        initial={{ scale: 4, rotate: -20 }}
        animate={{ scale: 1, rotate: 0 }}
        transition={{ type: "spring", duration: 0.5 }}
        className={`ResultSticker-sticker ${
          game?.last_turn?.correct ? "correct" : "incorrect"
        }`}
      >
        {game?.last_turn?.correct ? "CORRECT" : "INCORRECT"}!
      </motion.div>
    </motion.div>
  );
};

export default ResultSticker;
