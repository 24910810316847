import InstructionButtons from "../InstructionButtons";
import "./PlayerInstructions.css";

const PlayerInstructions = () => {
  return (
    <div className="PlayerInstructions">
      <h2>
        Look up to
        <br /> watch the tutorial!
      </h2>
      <InstructionButtons className="PlayerInstructions-buttons" />
    </div>
  );
};
export default PlayerInstructions;
