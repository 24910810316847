import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import useStore from "../../gameStore";
import { itemVariants } from "../../motionVariants";
import googleAds from "../../thirdParty/googleAds";
import PhysicsStickers from "../PhysicsStickers";
import "./PlayerScreenPlayWait.css";

const PlayerScreenPlayWait = () => {
  const { renderedGame: game, player, updatePlayer } = useStore();
  const floorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (player?.id) {
      updatePlayer(player.id, { last_active: new Date().toISOString() });
    }
    googleAds();
  }, []);

  return (
    <div className="PlayerScreenPlayWait">
      <div className="PlayerScreenPlayWait-main">
        <motion.h2
          variants={itemVariants}
          initial="hidden"
          animate="show"
          transition={{ type: "spring", duration: 0.1 }}
          className="PlayerScreenPlayWait-hang"
        >
          Hang tight.
          <br />
          It's not
          <br />
          your turn!
        </motion.h2>
        <PhysicsStickers
          className={game?.last_turn ? "PlayerScreenPlayWait-stickers" : ""}
          ballRadius={30}
          numBalls={5}
        />
        {game?.last_turn && (
          <motion.div
            ref={floorRef}
            variants={itemVariants}
            initial="hidden"
            animate="show"
            transition={{ type: "spring", duration: 0.1 }}
            className={
              "PlayerScreenPlayWait-last PlayerScreenPlayWait-last-" +
              (game!.last_turn!.correct ? "correct" : "incorrect")
            }
          >
            <h3 className="PlayerScreenPlayWait-last-text">
              Last answer was {game!.last_turn!.correct ? "correct" : "wrong"}:
            </h3>
            <p>{game!.last_turn!.question!.answer}</p>
          </motion.div>
        )}
      </div>
      <div className="PlayerScreenPlayWait-ad">
        <h3>advertisement</h3>
        <div className="PlayerScreenPlayWait-ad-container">
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=pub-2874829350905554"
            crossOrigin="anonymous"
          ></script>
          <ins
            className="adsbygoogle PlayerScreenPlayWait-adsbygoogle"
            data-ad-client="pub-2874829350905554"
            // data-adtest={process.env.NODE_ENV === "development" ? "on" : "off"}
            data-ad-slot="7065897763"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
      </div>
    </div>
  );
};

export default PlayerScreenPlayWait;
