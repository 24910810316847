import ReactDOM from "react-dom/client";
import { FirebaseAppProvider } from "reactfire";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBtWcqSzBWmAMBo0VhqIW65cgOE0PZDgRg",
  authDomain: "gen-smak.firebaseapp.com",
  projectId: "gen-smak",
  storageBucket: "gen-smak.appspot.com",
  messagingSenderId: "86799376075",
  appId: "1:86799376075:web:25c3b2a9cbe4fb1b5ec54e",
  measurementId: "G-CK4GB5XSFV",
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <App />
  </FirebaseAppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
