import { getCookie } from "../utils";

export async function thirdPartyInit() {
  const app = getCookie("app");
  if (app === "comcast") {
    let module = await import("./firebolt");
    module.thirdPartyInit();
  }
}

export async function thirdPartyError(code: string, message: string) {
  const app = getCookie("app");
  if (app === "comcast") {
    let module = await import("./firebolt");
    module.thirdPartyError(code, message);
  }
}

export async function thirdPartyExit(url: string) {
  const app = getCookie("app");
  if (app === "comcast") {
    let module = await import("./firebolt");
    module.thirdPartyExit();
  } else if (app === "android") {
    localStorage?.setItem("gensmak_premium", "exit");
  } else {
    window.location.href = url.startsWith("http") ? url : `http://${url}`;
  }
}
