import { motion } from "framer-motion";
import "./BonusSticker.css";
import "./SharedStyles.module.css";
const defaultVariants = {
  hidden: { scale: 4, opacity: 0 },
  show: { scale: 1, opacity: 1 },
  exit: { scale: 0, opacity: 0, transition: { duration: 0.5, delay: 0 } },
};
const BonusSticker = ({
  label = "+1",
  className,
  children,
  style: styleOverride,
  delay = 0,
  duration = 0.3,
  variants = {},
}: {
  label?: React.ReactNode | string | null;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  delay?: number;
  duration?: number;
  variants?: any;
}) => {
  return (
    <motion.div
      className={`BonusSticker ${className || ""}`}
      variants={{ ...defaultVariants, ...variants }}
      initial="hidden"
      animate="show"
      exit="exit"
      key={`bonus-sticker-${label}`}
      transition={{ type: "spring", delay, duration }}
      style={styleOverride}
    >
      {label && <p className="bonus">{label}</p>}
      {children && <p>{children}</p>}
    </motion.div>
  );
};

export default BonusSticker;
