import { useEffect, useState } from "react";
import useStore from "../../gameStore";
import GameCard from "../GameCard";
import QuestionSection from "../QuestionSection";
import ResultSticker from "../ResultSticker";
import style from "../SharedStyles.module.css";
import SlideUpModal from "../SlideUpModal";
import "./PlayerScreenPlayQuestion.css";

const PlayerScreenPlayQuestion = () => {
  const { updateTurn, renderedGame: game } = useStore();
  const [question, setQuestion] = useState(game!.turn!.question);
  const [showResult, setShowResult] = useState(false);
  const [isHintModalOpen, setIsHintModalOpen] = useState(false);
  const [isHintModalAvailable, setIsHintModalAvailable] = useState(true);

  const useHint = () => {
    updateTurn(game!.turn!.id, {
      used_hint: true,
    });
  };

  useEffect(() => {
    setQuestion(game!.turn!.question);
  }, [game!.turn!.question]);

  useEffect(() => {
    setIsHintModalAvailable(
      !game?.turn?.used_hint && !game?.turn?.is_ready && !showResult
    );
  }, [game?.turn?.used_hint, game?.turn?.is_ready, showResult]);

  useEffect(() => {
    setShowResult(game?.last_turn?.question?.id === game?.turn?.question?.id);
  }, [game?.last_turn?.question?.id, game?.turn?.question?.id]);
  return (
    <div className="PlayerScreenPlayQuestion">
      <div
        className={`PlayerScreenPlayQuestion-display 
           ${style[`generation-${question!.generation}`]}`}
      >
        <GameCard
          initial="hidden"
          animate="show"
          key={question!.id}
          className="PlayerScreenPlayQuestion-card"
          question={question!}
        />
        {showResult && <ResultSticker />}
      </div>
      <div className="PlayerScreenPlayQuestion-question">
        <QuestionSection
          title="Question"
          body={game?.turn?.question?.question!}
        />
        {(game?.turn?.used_hint || showResult) && (
          <QuestionSection title="Hint" body={game?.turn?.question?.hint!} />
        )}
        {showResult && (
          <QuestionSection title="Answer" body={question!.answer} />
        )}
        {!showResult && (
          <h3
            className="PlayerScreenPlayQuestion-prompt"
            style={{
              opacity: isHintModalOpen ? 0 : 1,
              marginBottom: isHintModalAvailable ? 0 : "3.6rem",
            }}
          >
            Tell the other team your final answer.
          </h3>
        )}
      </div>
      {isHintModalAvailable && (
        <SlideUpModal
          onClick={useHint}
          onChange={(isOpen) => setIsHintModalOpen(isOpen)}
          warning={
            <>
              Answer without a hint and you'll get a{" "}
              <strong>bonus point</strong>!
            </>
          }
          buttonCopy={"Give me a hint!"}
        />
      )}
    </div>
  );
};

export default PlayerScreenPlayQuestion;
