import { doc, DocumentSnapshot, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useFirestore } from "reactfire";
import useStore, { STATES } from "../gameStore";
import { useSlideTimer } from "../slideController";
import { getCookie } from "../utils";
import GameMarquee from "./GameMarquee";
import Loading from "./Loading";
import PlayerInstructions from "./player/PlayerInstructions";
import PlayerScreenGeneration from "./player/PlayerScreenGeneration";
import PlayerScreenJoin from "./player/PlayerScreenJoin";
import PlayerScreenName from "./player/PlayerScreenName";
import PlayerScreenPlay from "./player/PlayerScreenPlay";
import "./PlayerScreen.css";

const PlayerScreen = () => {
  let { code, player_id } = useParams();
  const {
    renderedGame: game,
    setLiveGame,
    loadGame,
    nextGame,
    player,
    loadPlayer,
    deletePlayer,
    setFreezeTime,
    gameState,
    questionSets,
    loadQuestionSets,
  } = useStore();
  useSlideTimer();
  const gameRef = doc(useFirestore(), "games", game?.id ?? "empty");
  useEffect(() => {
    if (!questionSets) {
      loadQuestionSets();
    }
  }, []);
  useEffect(() => {
    const removePlayer = (event: any) => {
      event.preventDefault();
      if (!player || !player.id || !game || game.started_at) {
        return;
      }
      deletePlayer(player.id!);
    };
    window.addEventListener("unload", removePlayer);
    return () => window.removeEventListener("unload", removePlayer);
  }, [player]);

  useEffect(() => {
    if (!player && player_id) {
      loadPlayer(player_id);
    }
  }, [player, player_id]);

  useEffect(() => {
    if (game) {
      // Firestore is ready and game is loaded, listen for updates
      const unsubscribe = onSnapshot(gameRef!, (doc: DocumentSnapshot<any>) => {
        const newData = doc.data();
        if (!game || game.updated_at !== newData.updated_at) {
          if (
            gameState === STATES.PLAYER_SCREEN_PLAY_QUESTION &&
            game!.turn!.id !== newData.turn?.id
          ) {
            setFreezeTime(5000, "turn");
          }
          setLiveGame(newData);
        }
      });
      return unsubscribe;
    } else if (!game && code) {
      loadGame(code);
    }
  }, [game]);
  switch (gameState) {
    case STATES.ERROR:
      return <Loading />;
    case STATES.PLAYER_CODE:
      return <Navigate to={`/${game!.id}`} />;
    case STATES.NEW_GAME:
      return nextGame ? <Navigate to={`/${nextGame}`} /> : null;
    case STATES.PLAYER_NEEDS_ID:
      return <Navigate to={`/${game!.id}/${player!.id}`} />;
    case STATES.PLAYER_SCREEN_REJOIN:
      return (
        <Navigate to={`/${getCookie("gameId")}/${getCookie("playerId")}`} />
      );
    default:
      return (
        <div id="PlayerScreen" className="screen">
          <GameMarquee />
          <main>
            {gameState === STATES.PLAYER_SCREEN_GENERATION && (
              <PlayerScreenGeneration />
            )}
            {gameState === STATES.PLAYER_INSTRUCTIONS && <PlayerInstructions />}
            {gameState === STATES.PLAYER_SCREEN_NAME && <PlayerScreenName />}
            {gameState === STATES.PLAYER_SCREEN_JOIN && <PlayerScreenJoin />}
            {(gameState === STATES.PLAYER_SCREEN_PLAY_GENERATION ||
              gameState === STATES.PLAYER_SCREEN_PLAY_QUESTION ||
              gameState === STATES.PLAYER_SCREEN_PLAY_CAPTAIN ||
              gameState === STATES.PLAYER_SCREEN_PLAY_WAIT ||
              gameState === STATES.PLAYER_SCREEN_PLAY_END) && (
              <PlayerScreenPlay />
            )}
          </main>
        </div>
      );
  }
};

export default PlayerScreen;
