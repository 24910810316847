import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useStore from "../gameStore";
import { getCookie } from "../utils";
import Loading from "./Loading";

const NewScreen = () => {
  const {
    renderedGame: game,
    unsetGame,
    createGame,
    unsetPlayer,
    error,
    setPassword,
  } = useStore();
  const [setQuery, setSetQuery] = useState<string>("");
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const setId = queryParams.get("set");
    if (setId) {
      setSetQuery("?set=" + setId);
    }
  }, []);

  useEffect(() => {
    unsetGame();
    unsetPlayer();

    const gp = localStorage?.getItem("gensmak_premium");
    const newGame: any = { code: "_" };
    const dev = getCookie("device_id");
    const set = getCookie("question_set");

    // Only add to data if specified
    if (dev) {
      newGame.owner = dev;
    }
    if (set) {
      newGame.question_sets = [set];
    } else if (gp == "true") {
      newGame.question_sets = [localStorage?.getItem("gensmak_premium_code")];
    }

    createGame(newGame);
  }, [createGame, unsetGame, unsetPlayer]);

  if (error || game === null || game === undefined) {
    return <Loading />;
  } else {
    return <Navigate to={"/host/" + game.id + setQuery} />;
  }
};

export default NewScreen;
