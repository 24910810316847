import { motion } from "framer-motion";
import { useState } from "react";
import useStore from "../../gameStore";
import eyeImg from "../../images/eye_slash.svg";
import { itemVariants } from "../../motionVariants";
import SecondaryButton from "../SecondaryButton";
import style from "../SharedStyles.module.css";
import "./PlayerScreenPlayCaptain.css";

const PlayerScreenPlayCaptain = () => {
  const { renderedGame: game, updateTurn } = useStore();
  const [showAnswer, setShowAnswer] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [initialRight, setInitialRight] = useState<boolean | null>(null);

  const clickCorrect = (correct: boolean) => {
    if (!confirm) {
      setConfirm(true);
      setInitialRight(correct);
    } else {
      updateTurn(game!.turn!.id, {
        correct,
      });
    }
  };
  const handleNevermind = () => {
    setConfirm(false);
    setInitialRight(null);
  };
  return (
    <div className="PlayerScreenPlayCaptain">
      <h2>{game?.turn?.question?.question}</h2>
      <div className="PlayerScreenPlayCaptain-answer-wrapper">
        <div
          className="PlayerScreenPlayCaptain-answer"
          onPointerDown={() => setShowAnswer(true)}
          onPointerUp={() => setShowAnswer(false)}
          onPointerOut={() => setShowAnswer(false)}
        >
          <p style={{ filter: showAnswer ? "blur(0)" : "blur(6px)" }}>
            {game?.turn?.question?.answer}
          </p>
          <div
            className="PlayerScreenPlayCaptain-answer-hidden"
            style={{ opacity: showAnswer ? 0 : 1 }}
          >
            <img src={eyeImg} alt="Hidden answer icon" />
            <p>Answer hidden</p>
          </div>

          <button
            className={`PlayerScreenPlayCaptain-peek ${
              showAnswer ? "active" : ""
            }`}
          >
            Peek at answer
          </button>
        </div>
      </div>
      <h2 className="PlayerScreenPlayCaptain-prompt">
        Did {game!.turn!.team!.name} get the <br />
        answer right or wrong?
      </h2>
      <div className="PlayerScreenPlayCaptain-buttons">
        <motion.button
          variants={itemVariants}
          transition={{ type: "spring", delay: 0.25, duration: 0.1 }}
          style={
            initialRight === true
              ? { flex: 1, maxWidth: "100%", opacity: 1 }
              : !confirm
              ? { flex: 1, maxWidth: "", opacity: 1 }
              : { flex: 0, maxWidth: "", opacity: 0 }
          }
          initial="hidden"
          animate="show"
          className={`PlayerScreenPlayCaptain-answer-button PlayerScreenPlayCaptain-correct ${style.roundShadow}`}
          onClick={() => {
            clickCorrect(true);
          }}
        >
          {confirm && initialRight === true ? "Definitely right!" : "Right"}
        </motion.button>
        <motion.button
          variants={itemVariants}
          transition={{ type: "spring", delay: 0.25, duration: 0.1 }}
          style={
            initialRight === false
              ? { flex: 1, maxWidth: "100%", opacity: 1 }
              : !confirm
              ? { flex: 1, maxWidth: "", opacity: 1 }
              : { flex: 0, maxWidth: "", opacity: 0 }
          }
          initial="hidden"
          animate="show"
          className={`PlayerScreenPlayCaptain-answer-button PlayerScreenPlayCaptain-wrong ${style.roundShadow}`}
          onClick={() => {
            clickCorrect(false);
          }}
        >
          {confirm && initialRight === false ? "Definitely wrong!" : "Wrong"}
        </motion.button>
      </div>
      <SecondaryButton
        onClick={handleNevermind}
        className="PlayerScreenPlayCaptain-nevermind"
        disabled={!confirm}
        style={
          confirm
            ? {
                opacity: 1,
                maxHeight: "100px",
                pointerEvents: "auto",
                padding: "1.6rem",
              }
            : { opacity: 0, maxHeight: 0, pointerEvents: "none", padding: 0 }
        }
      >
        Nevermind
      </SecondaryButton>
    </div>
  );
};

export default PlayerScreenPlayCaptain;
