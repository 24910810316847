import { useEffect } from "react";
import useStore, { STATES } from "../../gameStore";
import Loading from "../Loading";
import PlayerScreenEnd from "./PlayerScreenEnd";
import "./PlayerScreenPlay.css";
import PlayerScreenPlayCaptain from "./PlayerScreenPlayCaptain";
import PlayerScreenPlayGeneration from "./PlayerScreenPlayGeneration";
import PlayerScreenPlayQuestion from "./PlayerScreenPlayQuestion";
import PlayerScreenPlayWait from "./PlayerScreenPlayWait";

const PlayerScreenPlay = () => {
  const { player, updatePlayer, gameState } = useStore();

  useEffect(() => {
    if (player?.id) {
      updatePlayer(player.id, { last_active: new Date().toISOString() });
    }
  }, []);

  switch (gameState) {
    case STATES.PLAYER_SCREEN_PLAY_WAIT:
      return <PlayerScreenPlayWait />;
    case STATES.PLAYER_SCREEN_PLAY_GENERATION:
      return <PlayerScreenPlayGeneration />;
    case STATES.PLAYER_SCREEN_PLAY_QUESTION:
      return <PlayerScreenPlayQuestion />;
    case STATES.PLAYER_SCREEN_PLAY_CAPTAIN:
      return <PlayerScreenPlayCaptain />;
    case STATES.PLAYER_SCREEN_PLAY_END:
      return <PlayerScreenEnd />;
    case STATES.ERROR:
    default:
      return <Loading />;
  }
};

export default PlayerScreenPlay;
