import { motion } from "framer-motion";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef } from "react";
import { itemVariants } from "../motionVariants";
import style from "./MainButton.module.css";
import sharedStyles from "./SharedStyles.module.css";

const MainButton = ({
  onClick = () => {},
  children,
  className,
  style: styleOverride,
  delay = 0,
  duration = 0.3,
  shouldDebounce = true,
  ...rest
}: {
  onClick: () => any;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  delay?: number;
  duration?: number;
  shouldDebounce?: boolean;
} & any) => {
  const _onClick = useRef(onClick);
  useEffect(() => {
    _onClick.current = onClick;
  }, [onClick]);
  const debouncedClick = useCallback(
    shouldDebounce
      ? debounce(() => _onClick.current(), 500, {
          leading: true,
          trailing: false,
        })
      : () => _onClick.current(),
    []
  );
  return (
    <motion.button
      {...rest}
      variants={itemVariants}
      initial="hidden"
      animate="show"
      transition={{
        type: "spring",
        duration: duration,
        delay: delay,
      }}
      className={`${className || ""} ${style.button} ${
        sharedStyles.roundShadow
      }`}
      onClick={debouncedClick}
      style={styleOverride}
    >
      {children}
    </motion.button>
  );
};

export default MainButton;
