import { QRCodeSVG } from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import useStore from "../gameStore";
import caret from "../images/caret.svg";
import cart from "../images/cart.svg";
import close from "../images/close.svg";
import link from "../images/link.svg";
import list from "../images/list.svg";
import message from "../images/message.svg";
import refresh from "../images/refresh.svg";
import shield from "../images/shield.svg";
import smile from "../images/smile.svg";
import speaker from "../images/speaker.svg";
import { thirdPartyExit } from "../thirdParty/thirdParty";
import { getCookie, setCookie } from "../utils";
import "./GameMenu.css";
import MainButton from "./MainButton";
import HostExpansionCard from "./host/HostExpansionCard";
import HostExpansionMenu from "./host/HostExpansionMenu";
import HostStartHeader from "./host/HostStartHeader";
import { packSlides } from "./host/HostStartScreen";

const GameMenu = () => {
  const [openPack, setOpenPack] = useState<number | null>(null);
  const {
    setMuteSounds,
    muteSounds,
    setShowMenu,
    renderedGame: game,
    createGame,
    setShowJoinGame,
    setPreviewQuestionSet,
    setShowSetupInstructions,
  } = useStore();
  const [gensmakPremium, setGensmakPremium] = useState<string>("");
  const [windowSize, setWindowSize] = useState([1920, 1080]);
  const app = getCookie("app");
  const [focused, setFocused] = useState<string>("join");
  const focusDiv = useRef<HTMLDivElement>(null);

  const clickPlay = async (id: string) => {
    setCookie("question_set", id);
    await createGame({ code: "_", last_game: game!.id!, question_sets: [id] });
    setShowSetupInstructions(true);
    setShowJoinGame(true);
    setShowMenu(false);
  };

  //on resize:
  useEffect(() => {
    function handleResize() {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (focusDiv.current) {
      const currentFocused = document.getElementById(`GameMenu-${focused}`);
      if (currentFocused) {
        // get rectangle and make focusDiv match it as a border
        const rect = currentFocused.getBoundingClientRect();
        focusDiv.current.style.top = `${rect.top}px`;
        focusDiv.current.style.left = `${rect.left}px`;
        focusDiv.current.style.width = `${rect.width}px`;
        focusDiv.current.style.height = `${rect.height}px`;
      }
    }
  }, [focused, windowSize]);

  const clickUpgrade = () => {
    localStorage?.setItem("gensmak_premium", "buy");
    // !!! NOTE: Just doing this for demo today!
    // setGensmakPremium("buy");
    setGensmakPremium("true");
    checkUpgrade();
  };

  const checkUpgrade = () => {
    var gp = localStorage?.getItem("gensmak_premium");
    if (gp === "true") {
      setGensmakPremium("true");
    } else if (gp === "buy") {
      setTimeout(checkUpgrade, 1000);
    } else if (gp === "") {
      setGensmakPremium("");
    }
  };

  useEffect(() => {
    localStorage?.setItem("gensmak_premium", ""); // temp
    var gp = localStorage?.getItem("gensmak_premium");
    if (gp === "buy") {
      gp = "";
      localStorage?.setItem("gensmak_premium", "");
    }

    setGensmakPremium(gp ?? "");
  }, []);
  const newGame = () => {
    setShowMenu(false);
    setShowJoinGame(false);
    setPreviewQuestionSet(null);
    createGame({ code: "_", last_game: game!.id! });
  };
  const exitGame = () => {
    thirdPartyExit(process.env.REACT_APP_SERVER_URL_SHORT!);
  };
  const menuItems = [
    {
      img: link,
      text: "Join the game",
      id: "join",
      show: true,
      showOnFocus: (
        <div className="GameMenu-join-panel" key="GameMenu-join-panel">
          <h3>
            Join the game
            <br />
            from your device!
          </h3>
          <QRCodeSVG
            value={process.env.REACT_APP_SERVER_URL_SHORT! + game!.code}
            className="GameMenu-join-qrcode"
          />
          <p className="GameMenu-join-link">
            {process.env.REACT_APP_SERVER_URL_SHORT! + game!.code}
          </p>
        </div>
      ),
    },
    {
      img: list,
      text: "Rules",
      id: "rules",
      show: true,
      showOnFocus: (
        <div className="GameMenu-rules-panel" key="GameMenu-rules-panel">
          <h3>How to play</h3>
          <p>
            Win by being the first team to 15 points and answering one question
            from every generation.
          </p>
          <p>
            Use the generations, categories, and difficulty levels on the front
            of the cards to inform your team’s selection. If you don’t know the
            answer, take the hint!
          </p>
          <p>Earn up to 3 points each time you pick a card: </p>
          <ul>
            <li>+1 point for correctly answering a trivia question</li>
            <li>
              +1 bonus point for correctly answering a question outside your
              generation
            </li>
            <li>+1 bonus point for correctly answering without a hint</li>
          </ul>
        </div>
      ),
    },
    {
      img: cart,
      text: "My decks",
      id: "upgrade",
      show: true,
      showOnFocus: (
        <div className="GameMenu-upgrade-panel" key="GameMenu-upgrade-panel">
          {openPack === null ? (
            <>
              <HostStartHeader small={true} />
              <div className="StartScreen-packs">
                {packSlides.map((pack, i) => (
                  <HostExpansionCard
                    key={i}
                    pack={pack}
                    onClick={() => setOpenPack(i)}
                    startFocus={false}
                  />
                ))}
              </div>
            </>
          ) : (
            <>
              <HostExpansionMenu
                pack={packSlides[openPack]}
                vertical={true}
                onClickPlay={() => clickPlay(packSlides[openPack].id)}
                onClickClose={() => setOpenPack(null)}
              />
            </>
          )}
        </div>
      ),
    },
    {
      img: speaker,
      text: "Sound",
      id: "sound",
      show: app !== "android",
      showOnFocus: (
        <div className="GameMenu-sound-panel" key="GameMenu-sound-panel">
          <div>
            <h3>Sound Effects</h3>
            <button
              className={`GameMenu-sound-button ${muteSounds ? "off" : "on"}`}
              onClick={() => setMuteSounds(false)}
            >
              On
            </button>
            <button
              className={`GameMenu-sound-button ${muteSounds ? "on" : "off"}`}
              onClick={() => setMuteSounds(true)}
            >
              Off
            </button>
          </div>
          {/* NOTE: Leaving in but commented until music is licensed
          <div>
            <h3>Music</h3>
            <button
              className={`GameMenu-sound-button ${muteMusic ? "off" : "on"}`}
              onClick={() => setMuteMusic(false)}
            >
              On
            </button>
            <button
              className={`GameMenu-sound-button ${muteMusic ? "on" : "off"}`}
              onClick={() => setMuteMusic(true)}
            >
          </div> */}
        </div>
      ),
    },
    {
      img: shield,
      text: "Privacy policy",
      id: "privacy",
      show: true,
      showOnFocus: (
        <div
          className="GameMenu-links-panel GameMenu-privacy-panel"
          key="GameMenu-privacy-panel"
        >
          <p>
            To read <i>GENSMAK!</i>’s Privacy Policy, visit:
          </p>
          <h2>gensmak.com/policies/privacy-policy</h2>
          <p>
            To read <i>GENSMAK!</i>’s Terms of Service, visit:
          </p>
          <h2>gensmak.com/policies/terms-of-service</h2>
        </div>
      ),
    },
    {
      img: message,
      text: "Support + feedback",
      id: "support",
      show: true,
      showOnFocus: (
        <div className="GameMenu-links-panel" key="GameMenu-support-panel">
          <p>For support, email:</p>
          <h2>support@gensmak.com</h2>
          <p>To submit feedback, email:</p>
          <h2>ideas@gensmak.com</h2>
        </div>
      ),
    },
    {
      img: refresh,
      text: "New game",
      id: "new",
      show: true,
      showOnFocus: (
        <div className="GameMenu-new-panel" key="GameMenu-new-panel">
          <img
            src={smile}
            alt="smile icon"
            className="GameMenu-smile-sticker"
          />
          <p>
            If you start a new game, <br />
            the current one will end.
          </p>
          <MainButton onClick={newGame}>New game!</MainButton>
        </div>
      ),
    },
    {
      img: close,
      text: "Exit",
      id: "exit",
      show: app === "comcast" || app === "android",
      showOnFocus: (
        <div className="GameMenu-exit-panel" key="GameMenu-nexit-panel">
          <img
            src={smile}
            alt="smile icon"
            className="GameMenu-smile-sticker"
          />
          <p>Exit the game.</p>
          <MainButton onClick={exitGame}>Exit</MainButton>
        </div>
      ),
    },
  ];
  return (
    <div className="GameMenu-menu">
      <div className="GameMenu-items">
        <button
          className="GameMenu-close GameMenu-item"
          onClick={() => setShowMenu(false)}
        >
          <img className="GameMenu-icon" src={close} alt="menu-icon" />
        </button>
        <div className={`GameMenu-focus`} ref={focusDiv}></div>
        {menuItems
          .filter(({ show }) => show)
          .map(({ img, text, id, showOnFocus }, i) => (
            <button
              autoFocus={i === 0}
              key={id + i}
              id={`GameMenu-${id}`}
              className={`GameMenu-item ${
                id === focused ? "focused" : "unfocused"
              }`}
              onFocus={() => setFocused(id)}
              onClick={() => setFocused(id)}
            >
              <img className="GameMenu-icon" src={img} alt="menu-icon" />
              <span>{text}</span>
              {showOnFocus && (
                <img
                  className="GameMenu-icon GameMenu-caret"
                  src={caret}
                  alt="menu-icon"
                />
              )}
            </button>
          ))}
      </div>
      <div className="GameMenu-content">
        {menuItems.map(({ id, showOnFocus }) => id === focused && showOnFocus)}
      </div>
      {/* NOTE: Leaving this in for now, needs to be integrated after demo
          {app == "android" && gensmakPremium === "true" && (
            <div className="GameMenu-menu-item">Premium Unlocked!</div>
          )}
          {app == "android" && gensmakPremium === "" && (
            <button
              className={"GameMenu-menu-item " + style.roundShadow}
              onClick={clickUpgrade}
            >
              Upgrade to Premium
            </button>
          )}  */}
    </div>
  );
};
export default GameMenu;
